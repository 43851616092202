import './index.scss'
import Swiper from 'swiper/swiper-bundle.min.js'

//首页banner图
var interleaveOffset = 0.5; //视差比值

var swiperOptions = {
    loop: true,
    speed: 1000,
    watchSlidesProgress: true,
    autoplay: {
        delay: 5000,
        stopOnLastSlide: false,
        disableOnInteraction: false,
    },
    pagination: {
        el: ".index-banner .swiper-pagination",
        clickable: true,
    },
    on: {
        init: function (swiper) {
            $('.index-banner .banner-text .item').eq(this.realIndex).addClass('active').siblings().removeClass('active')
        },
        slideChangeTransitionStart: function () {
            $('.index-banner .banner-text .item').eq(this.realIndex).addClass('active').siblings().removeClass('active')
        },
        progress: function (swiper) {
            for (var i = 0; i < swiper.slides.length; i++) {
                var slideProgress = swiper.slides[i].progress;
                var innerOffset = swiper.width * interleaveOffset;
                var innerTranslate = slideProgress * innerOffset;
                swiper.slides[i].querySelector(".slide-inner").style.transform =
                    "translate3d(" + innerTranslate + "px, 0, 0)";
            }
        },
        touchStart: function (swiper) {
            for (var i = 0; i < swiper.slides.length; i++) {
                swiper.slides[i].style.transition = "";
            }
        },
        setTransition: function (swiper, speed) {
            for (var i = 0; i < swiper.slides.length; i++) {
                swiper.slides[i].style.transition = speed + "ms";
                swiper.slides[i].querySelector(".slide-inner").style.transition =
                    speed + "ms";
            }
        }
    }
};

var bannerSwiper = new Swiper(".index-banner .swiper", swiperOptions);


//页面滚动监听
var beforeScroH = $(document).scrollTop();
$(document).scroll(function () {
    var scroH = $(document).scrollTop(); //滚动高度
    var viewH = $(window).height(); //可见高度
    var contentH = $(document).height(); //内容高度

    //滚动文字效果
    //判断页面是上滚还是下滚
    var delta = scroH - beforeScroH;
    if (delta > 0) {
        //下滚
        $('.scroll-text').removeClass('slide-right')
        $('.scroll-text .txt-scroll').css('transform', 'translateX(' + -scroH / 5 + 'px)')
    }
    if (delta < 0) {
        //上滚
        $('.scroll-text').addClass('slide-right')
        $('.scroll-text.slide-right .txt-scroll').css('transform', 'translateX(' + -scroH / 5 + 'px)')
    }
    beforeScroH = scroH;

    //首页产品部分图片位移
    let proTop1 = $('.index-product .product ul li').eq(0).offset().top
    let proTop2 = $('.index-product .product ul li').eq(1).offset().top

    if (scroH > proTop1 - viewH){
        $('.index-product .product ul li').eq(0).find('.item-img b').css('transform', 'translateY(-' + (scroH - proTop1 + viewH)/10 +'px)')
    }
    if (scroH > proTop2 - viewH) {
        $('.index-product .product ul li').eq(1).find('.item-img b').css('transform', 'translateY(-' + (scroH - proTop2 + viewH) / 10 + 'px)')
        $('.index-product .bg-img img').css('transform', 'translateY(' + (scroH - proTop2 + viewH) / 10 + 'px)')
        if($(window).width() >= 768){
            $('.index-product .bg-img img').css('transform', 'translateY(' + (scroH - proTop2 + viewH) / 10 + 'px)')
        }else{
            $('.index-product .bg-img img').css('transform', 'translateY(-' + (scroH - proTop2 + viewH) / 20 + 'px)')
        }
    }

    //首页产品质量与安全部分图片位移
    let qualityTop = $('.index-quality .right').offset().top
    if (scroH > proTop1 - viewH && $(window).width() >= 768) {
        $('.index-quality .right img').css('transform', 'translateY(' + (scroH - proTop2 + viewH) / 30 + 'px)')
    }
});